export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [4,[2]],
		"/auth": [11],
		"/(app)/c/[id]": [5,[2]],
		"/error": [12],
		"/s/[id]": [13],
		"/(app)/workspace": [6,[2,3]],
		"/(app)/workspace/documents": [7,[2,3]],
		"/(app)/workspace/models": [8,[2,3]],
		"/(app)/workspace/models/create": [9,[2,3]],
		"/(app)/workspace/models/edit": [10,[2,3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';